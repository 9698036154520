
import moment from "moment";
import { defineComponent, ref, computed, onMounted } from "vue";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default defineComponent({
  name: "Teacher-list",
  props: {
    id: String,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    const loading = ref<boolean>(true);
    const notificationsList = ref<any[]>([]);
    const currentPage = ref(1);
    const pageSize = 10;
    const totalItems = ref(0);

    const paginatedNotifications = computed(() => {
      const start = (currentPage.value - 1) * pageSize;
      const end = start + pageSize;
      return notificationsList.value.slice(start, end);
    });

    const handleCurrentChange = (val: number) => {
      currentPage.value = val;
    };

    onMounted(async () => {
      loading.value = true;
      try {
        const { data } = await ApiService.get("/notifications");
        const mappedData = data.notifications.map((notif: any) => {
          if (notif.refType == "newHomeworkToValidate")
            notif.link = "/exercisesValidation/" + notif.ref;
          else if (notif.refType == "newCourseToValidate")
            notif.link = "/coursesValidation/" + notif.ref;
          else if (notif.refType == "newMeeting")
            notif.link = "/teacher/meetings";
          else if (notif.refType == "newResearch")
            notif.link = "/teacher/research/" + notif.ref;
          else if (notif.refType == "StudentPunishmentRejected")
            notif.link = "/student/" + notif.ref.split("#")[1] + "/punishments";
          else if (notif.refType == "newValidatedHomework")
            notif.link = "/exercise/" + notif.ref;
          else if (notif.refType == "newSubmitedHomework")
            notif.link = "/exercise/" + notif.ref;
          else if (notif.refType == "newValidatedCourse")
            notif.link = "/course/" + notif.ref;
          else if (notif.refType == "newActuality") notif.link = "/actualites";
          else if (notif.refType == "newComment") notif.link = "/actualites";
          else if (notif.refType == "newEvent") notif.link = "/teacher/meetings";
          else notif.link = "/";
          return notif;
        });

        notificationsList.value = mappedData;
        totalItems.value = mappedData.length;
      } catch (error) {
        console.error("Failed to fetch notifications:", error);
      } finally {
        loading.value = false;
      }

      setCurrentPageBreadcrumbs(t("notification.notifications"), []);
    });

    return {
      t,
      moment,
      loading,
      paginatedNotifications,
      currentPage,
      totalItems,
      handleCurrentChange,
      apiUrl: store.getters.serverConfigUrl.base_url + "/",
    };
  },
});
